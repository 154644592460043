<template>
    <router-view/>
</template>
<style lang="scss">
	@import "assets/fonts/font.css";
	* {
	  margin: 0;
	  padding: 0;
	}
	#app {
	  font-family: "FZLanTYK_Zhun_202304031300", "MyriadPro_Regular_202304031300", "serif";
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  text-align: center; 
	  color: #2c3e50;  
	}
	 html, body {
	 
	     max-width: 100%;
	 
	     overflow-x: hidden;
	 
	 }
</style>
